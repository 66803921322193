import RenderCodeBlock from "../components/RenderCodeBlock";
import Blogs from "../components/sections/Post";
import demoImage from "./../assets/Images/programmer.jpg";
import parse from "html-react-parser";

const detail = [
  {
    tag: "h2",
    text: "Overview",
  },
  {
    tag: "p",
    text: `Javascript is a <span className='highlight'>single-threaded </span> and synchronous programming language which means only a single thing can happen at a time, but some tasks in javascript can run in parallel due to its asynchronous nature. Event Loop plays a major role in the execution of asynchronous functions. The event loop plays a crucial role in scheduling which operations our only thread should be performing at any given point in time. Hence the event loop helps us to understand Node's asynchronous processes and its non-blocking I/O nature.`,
  },
  {
    tag: "h1",
    text: "Pre-requisites",
  },
  {
    tag: "p",
    text: `Javascript is a <span className='highlight'>single-threaded </span> and synchronous programming language which means only a single thing can happen at a time, but some tasks in javascript can run in parallel due to its asynchronous nature. Event Loop plays a major role in the execution of asynchronous functions. The event loop plays a crucial role in scheduling which operations our only thread should be performing at any given point in time. Hence the event loop helps us to understand Node's asynchronous processes and its non-blocking I/O nature.`,
  },
  {
    tag: "ul",
    text: `Javascript is a <span className='highlight'>single-threaded </span> and synchronous programming language which means only a single thing can happen at a time, but some tasks in javascript can run in parallel due to its asynchronous nature. Event Loop plays a major role in the execution of asynchronous functions. The event loop plays a crucial role in scheduling which operations our only thread should be performing at any given point in time. Hence the event loop helps us to understand Node's asynchronous processes and its non-blocking I/O nature.`,
    list: [
      "<b>Call Stack :</b> It is a data structure where all the subsequent function invocation or new execution context is pushed to this call stack. Call stack works on the principle of Last In, First Out, i.e. the last statement pushed will be popped out first. It keeps the record of which statement is currently being executed as the current statement is pushed to the top of the stack. Once the execution context is done with a task, that task is popped out.",
    ],
  },
  {
    tag: "ol",
    text: `Javascript is a <span className='highlight'>single-threaded </span> and synchronous programming language which means only a single thing can happen at a time, but some tasks in javascript can run in parallel due to its asynchronous nature. Event Loop plays a major role in the execution of asynchronous functions. The event loop plays a crucial role in scheduling which operations our only thread should be performing at any given point in time. Hence the event loop helps us to understand Node's asynchronous processes and its non-blocking I/O nature.`,
    list: [
      "<b>Call Stack :</b> It is a data structure where all the subsequent function invocation or new execution context is pushed to this call stack. Call stack works on the principle of Last In, First Out, i.e. the last statement pushed will be popped out first. It keeps the record of which statement is currently being executed as the current statement is pushed to the top of the stack. Once the execution context is done with a task, that task is popped out.",
    ],
  },
  // {
  //   tag: "ol",
  //   text: [""],
  // },
  // {
  //   tag: "li",
  //   text: [""],
  // },
];
function BlogDetail() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <div className="blog-header-container">
            <h1>Event Loop in Node.js</h1>
            <div className="author-detail">
              <div className="autor">By Kawal Jain</div>
              <div>Last updated: 4 May 2023</div>
            </div>
            <div className="img-container mb-4">
              <img
                src={demoImage}
                className="img-fluid"
                style={{
                  maxWidth: "80%",
                }}
              />
            </div>
          </div>

          <div className="content-container mt-4 ">
            <div className="content mb-4">
              {detail.map((d) => {
                if (d.tag === "ul" || d.tag === "ol") {
                  let html = `<${d.tag}>`;
                  html += d.list.map((l) => `<li>${l}</li>`);
                  html += `</${d.tag}>`;
                  return parse(html);
                } else {
                  let html = `<${d.tag}>${d.text} </${d.tag}>`;
                  return parse(html);
                }
              })}

              <ul>
                <li>
                  Call Stack : It is a data structure where all the subsequent
                  function invocation or new execution context is pushed to this
                  call stack. Call stack works on the principle of Last In,
                  First Out, i.e. the last statement pushed will be popped out
                  first. It keeps the record of which statement is currently
                  being executed as the current statement is pushed to the top
                  of the stack. Once the execution context is done with a task,
                  that task is popped out.
                </li>
              </ul>
              <h1>Benefits of Event Emitters in Node.js</h1>
              <ul>
                <li>Provides better scalability and fault tolerance.</li>
                <li>Provides better scalability and fault tolerance.</li>
                <li>Provides better scalability and fault tolerance.</li>
                <li>Provides better scalability and fault tolerance.</li>
              </ul>
              <h2>How does an Event Loop in Node.js Works?</h2>
              <div className="code-block">
                <code>
                  <RenderCodeBlock
                    showLineNumbers={true}
                    language={"javascript"}
                    code={`console.log('Hello the execution has started');
                      setTimeout(function cbfunc1() { console.log('callback has been executed !');}, 5000);
                      console.log('Execution finished');`}
                  />
                </code>
              </div>
              <p>
                Let’s <span className="highlight">execute </span> the code given
                above and see what happens:
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetail;
