import React, { useState } from "react";
import { IoMenuOutline } from "react-icons/io5";
import ContainerLayout from "../layouts/Container";
import { Bio } from "../data/constants";

import { HashLink } from "react-router-hash-link";

const menuList = [
  {
    title: "Skills",
    id: "skills",
    link: "/#skills",
    isLink: false,
    isTarget: false,
  },
  {
    title: "About",
    id: "about",
    link: "/#about",
    isLink: false,
    isTarget: false,
  },
  {
    title: "Experience",
    id: "experience",
    link: "/#experience",
    isLink: false,
    isTarget: false,
  },
  {
    title: "Projects",
    id: "projects",
    link: "/#projects",
    isLink: false,
    isTarget: false,
  },
  {
    title: "Blog",
    id: "blog",
    link: "https://anvikjs.com/",
    isLink: false,
    isTarget: true,
  },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="nav-main-container">
      <ContainerLayout>
        <div className="nav-container">
          <HashLink smooth className="nav-logo-container " to={"/#overviewMe"}>
            <div className="color-text">&lt;</div>Kawal
            <div className="color-primary">/</div>Jain
            <div className="color-text">&gt;</div>
          </HashLink>
          <div
            className="mobile-icon-container"
            onClick={() => setIsOpen(!isOpen)}
          >
            <IoMenuOutline size={25} />
          </div>
          <div className="nav-items">
            {menuList.map((list) => {
              return (
                <HashLink
                  smooth
                  key={list.id}
                  className="nav-item-text"
                  to={list.link}
                  target={list.isTarget ? "_blank" : "_self"}
                >
                  {list.title}
                </HashLink>
              );
            })}
            {/* <HashLink
              className="nav-item-text"
              onClick={() => {
                window.open(Bio.resume, "_blank");
              }}
            >
              Resume
            </HashLink> */}
          </div>
          {isOpen && (
            <div className="nav-mobile-menu-container " isOpen={isOpen}>
              {menuList.map((list) => {
                return (
                  <div
                    key={list.id}
                    onClick={() => {
                      if (list.isLink) {
                        window.open(list.link, "_blank");
                      } else {
                        window.location.href = `${list.link}`;
                      }
                      setIsOpen(!isOpen);
                    }}
                    className="nav-item-text"
                  >
                    {list.title}
                  </div>
                );
              })}
              {/* <div
                className="nav-item-text"
                onClick={() => {
                  window.open(Bio.resume, "_blank");
                  setIsOpen(!isOpen);
                }}
              >
                Resume
              </div> */}
            </div>
          )}
          <div
            className="say-hi-container"
            onClick={() => {
              window.location.href = "mailto:kawaljain03@gmail.com";
            }}
          >
            <div className="color-text">&lt;</div>Say
            <div className="color-primary">/</div>
            {" hiii"}
            <div className="color-text">&gt;</div>
          </div>
        </div>
      </ContainerLayout>
    </div>
  );
};

export default Navbar;
