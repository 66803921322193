import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";

function RenderCodeBlock({ code, language, showLineNumbers }) {
  return (
    <CopyBlock
      text={code}
      customStyle={{
        height: "200px",
        overflow: "scroll",
      }}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={dracula}
    />
  );
}
export default RenderCodeBlock;
